<template>
  <div class="content-editor" ref="editorContainer" style="position: relative">
    <tiptap-vuetify
      ref="editor"
      v-model="content"
      :extensions="extensions"
      class="content-editor__editor"
      :class="{ 'error--text': error }"
      @mouseup.native="handleTextSelection"
    />
    <v-menu
      v-model="showModalFinesse"
      offset-x
      :close-on-content-click="false"
      :style="{ top: menuPosition.top + 'px', left: menuPosition.left + 'px' }"
      attach
    >
      <v-list class="pa-2">
        <div class="wrapper-option">
          <v-btn
            color="primary"
            class="btn-group-option"
            text
            @click="showFinesseOptions = !showFinesseOptions"
            :disabled="loading"
          >
            <v-icon>{{ icons.star }}</v-icon>
          </v-btn>
          <div class="space"></div>
          <v-btn
            class="btn-group-option"
            color="primary"
            text
            @click="showPromptModal"
            :disabled="loading"
          >
            <span class="ask-ai">Ask AI</span>
          </v-btn>
        </div>
        <template v-if="showFinesseOptions && !loading">
          <v-list-item v-for="(option, index) in finesseOptions" :key="index">
            <v-list-item-title
              @click="generateText({ improvement_type: option.value })"
            >
              {{ option.text }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>

    <v-dialog
      v-model="showPrompt"
      max-width="520px"
      @click:outside="cancelPrompt"
    >
      <v-form ref="formGenerateText" @submit.prevent="generateText">
        <v-card>
          <v-card-text class="pb-0">
            <v-container class="pb-0">
              <v-row>
                <v-col cols="12">
                  <label class="text-field-label">Prompt</label>
                  <v-text-field
                    flat
                    solo
                    required
                    hide-details
                    class="mt-2"
                    placeholder="What do you want to write about?"
                    v-model="promptText"
                    :loading="loading"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="pb-5 pt-2 px-6">
            <v-spacer></v-spacer>
            <v-btn
              color="black"
              class="font-weight-bold"
              text
              @click="cancelPrompt()"
            >
              Close
            </v-btn>
            <v-btn
              color="white"
              :class="{ primary: !!promptText }"
              text
              name="generateText"
              :loading="loading"
              :disabled="!promptText"
              @click="generateWithPrompt"
            >
              Send
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog
      v-model="showModalTryAgain"
      max-width="720px"
      @click:outside="cancelModalInsert"
    >
      <v-form ref="formGenerateText" @submit.prevent="generateAgain">
        <v-card>
          <v-card-text class="pb-0">
            <v-container class="pb-0">
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    flat
                    solo
                    required
                    hide-details
                    class="mt-2"
                    v-model="tmpInsertText"
                    readonly
                  ></v-textarea>
                  <v-text-field
                    flat
                    solo
                    required
                    hide-details
                    class="mt-2"
                    placeholder="What do you want to write about?"
                    v-model="promptTextAgain"
                    :loading="loading"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="pb-5 pt-2 px-6">
            <v-spacer></v-spacer>
            <v-btn
              color="black"
              class="font-weight-bold"
              text
              @click="cancelModalInsert()"
            >
              Close
            </v-btn>
            <v-btn
              color="white"
              :class="{ primary: !!tmpInsertText }"
              text
              name="generateText"
              :loading="loading"
              :disabled="!tmpInsertText"
              @click="handleInsertText"
            >
              Insert
            </v-btn>
            <v-btn
              color="white"
              :class="{ primary: !!promptTextAgain }"
              text
              name="generateText"
              :loading="loading"
              :disabled="!promptTextAgain"
              @click="generateAgain"
            >
              Try Again
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <div v-if="error" class="error-message mt-2">
      {{ error }}
    </div>
    <Loading :value="loading" />
  </div>
</template>

<script>
// import the component and the necessary extensions
import {
  TiptapVuetify,
  Heading,
  Bold,
  // Italic,
  // Strike,
  // Underline,
  // Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  // Link,
  // Blockquote,
  HardBreak,
  // HorizontalRule,
  History,
} from 'tiptap-vuetify'

import { FINESSES } from '@/utils/enums/New'
import { mdiStarFourPoints } from '@mdi/js'
import { mapActions, mapState } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    value: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
  },
  components: {
    TiptapVuetify,
    Loading,
  },
  data() {
    return {
      extensions: [
        History,
        // Blockquote,
        // [
        // [
        //   Link,
        //   {
        //     openOnClick: true, // Ensure links are clickable
        //     autolink: true, // Auto-detect links
        //     linkOnPaste: true, // Enable pasting links
        //     HTMLAttributes: {
        //       target: '_blank', // Open in new tab
        //       rel: 'noopener noreferrer',
        //     },
        //   },
        // ],
        // Underline,
        // Strike,
        // Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3, 4, 5],
            },
          },
        ],
        Bold,
        // Code,
        // HorizontalRule,
        Paragraph,
        HardBreak,
      ],
      // starting editor's content
      content: this.value,
      icons: {
        star: mdiStarFourPoints,
      },
      finesseOptions: FINESSES,
      selectedText: '',
      showModalFinesse: false,
      showFinesseOptions: false,
      loading: false,
      menuPosition: { top: 0, left: 0 },
      showPrompt: false,
      promptText: '',
      showModalTryAgain: false,
      tmpInsertText: '',
      promptTextAgain: '',
    }
  },
  watch: {
    value(v) {
      this.content = v
    },
    content(value) {
      this.$emit('input', value)
    },
    showModalFinesse(value) {
      if (!value) {
        setTimeout(() => {
          this.menuPosition = {
            top: 0,
            left: 0,
          }
          this.showFinesseOptions = false
        }, 200)
      }
    },
  },

  computed: {
    ...mapState({
      permissions: (state) => state.auth.permissions,
    }),

    hasPermissionAi() {
      return validatePermissions(
        [PERMISSION.USE_AI_FOR_ARTICLES],
        this.permissions
      )
    },
  },

  methods: {
    ...mapActions({
      improveTextAi: 'news/improveTextAi',
    }),

    handleTextSelection() {
      if (!this.hasPermissionAi) return

      setTimeout(() => {
        const selection = window.getSelection()
        const selectedText = selection ? selection.toString().trim() : ''

        if (selectedText) {
          // Get cursor position
          const range = selection.getRangeAt(0)
          const rect = range.getBoundingClientRect()
          const editorContainer =
            this.$refs.editorContainer.getBoundingClientRect()

          // Calculate position relative to editor
          this.menuPosition = {
            top: rect.top - editorContainer.top + 5,
            left: rect.left - editorContainer.left,
          }

          this.selectedText = selectedText
          this.showModalFinesse = true
        } else {
          this.selectedText = ''
          this.showModalFinesse = false
        }
      }, 100)
    },

    async generateText(value, text = this.selectedText) {
      if (this.loading) return
      this.loading = true

      await this.improveTextAi({ ...value, text: text })
        .then((data) => {
          const { editor } = this.$refs.editor
          if (!editor || !data?.improved_text) return
          this.showModalTryAgain = true
          this.tmpInsertText = data?.improved_text
          this.promptTextAgain = ''
        })
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
          this.showModalFinesse = false
          this.showFinesseOptions = false
        })
    },

    cancelPrompt() {
      this.showPrompt = false
      this.promptText = ''
    },

    showPromptModal() {
      this.showPrompt = true
      this.showFinesseOptions = false
    },

    generateWithPrompt() {
      this.showPrompt = false
      this.generateText({ prompt: this.promptText })
    },

    cancelModalInsert() {
      this.tmpInsertText = ''
      this.promptTextAgain = ''
      this.promptText = ''
      this.showModalTryAgain = false
    },

    generateAgain() {
      this.showModalTryAgain = false
      this.generateText({
        prompt: this.promptTextAgain,
        text: this.tmpInsertText,
      })
    },

    handleInsertText() {
      const { editor } = this.$refs.editor
      if (!editor || !this.tmpInsertText) return

      const { state, view } = editor
      const { selection } = state

      // Replace only the selected text with the improved content
      view.dispatch(
        state.tr.insertText(this.tmpInsertText, selection.from, selection.to)
      )
      this.cancelModalInsert()
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .content-editor {
  &__editor {
    .tiptap-vuetify-editor__content {
      background-color: #f1f3f4;
      min-height: 300px;
      resize: vertical;
      overflow: auto;
      transition: height 0.1s ease;
    }
  }
}

::v-deep .v-card.v-sheet {
  box-shadow: none !important;
}

.error-message {
  color: #c21e51;
  font-size: 12px;
  padding-left: 12px;
}

.v-menu--attached {
  position: absolute;
}

.wrapper-option {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  .btn-group-option {
    text-transform: lowercase !important;
  }

  .space {
    width: 1px;
    height: 22px;
    background: var(--v-primary-base);
  }

  .ask-ai {
    text-transform: lowercase;
    font-size: 18px;
    font-weight: 600;
  }

  .ask-ai::first-letter {
    text-transform: uppercase;
  }
}
</style>
