<template>
  <div class="news-form">
    <div ref="form" class="news-form__form" @submit.prevent="submit">
      <h3 class="py-5 primary--text">{{ header }}</h3>
      <v-row>
        <v-col cols="12" md="6">
          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Buildings</label>
          <SelectMultipleBuildings
            flat
            solo
            required
            class="mt-2"
            :label="'Select buildings'"
            :placeholder="'Select buildings'"
            v-model="form.building_id"
            :pre-select="!isUpdate"
            :error-messages="form.$getError('building_id')"
            :loading="form.$busy"
            :disabled="form.$busy"
            :deletableChips="true"
            :isHasSelectAll="true"
            :items="news ? news.buildings : []"
          />
          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Title</label>
          <v-text-field
            flat
            solo
            required
            class="mt-2"
            placeholder="Title"
            v-model="form.title"
            :error-messages="form.$getError('title')"
            :loading="form.$busy"
            :disabled="form.$busy"
          ></v-text-field>

          <template>
            <div class="w-100 d-flex justify-space-between">
              <div>
                <span class="red--text"><strong>* </strong></span
                ><label class="text-field-label">Content</label>
              </div>

              <v-btn
                v-if="hasPermissionAi"
                class="primary"
                text
                @click="() => (showPrompt = true)"
              >
                <div class="btn-generate">
                  Get started with
                  <v-icon class="icon-ai">{{ icons.star }}</v-icon>
                  <span class="btn-generate__text-ai">ai</span>
                </div>
              </v-btn>
            </div>
          </template>

          <content-editor
            class="my-3"
            v-model="form.content"
            :error="
              form.$getError('content') ? form.$getError('content')[0] : ''
            "
          />

          <v-row dense class="my-6">
            <v-col cols="12" md="6">
              <v-checkbox
                v-model="form.is_active"
                hide-details="auto"
                label="Set as active"
                class="mt-0"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="6">
              <v-checkbox
                v-model="form.is_comment_allowed"
                hide-details="auto"
                label="Comments allowed"
                class="mt-0"
              ></v-checkbox>
            </v-col>
          </v-row>

          <label class="text-field-label">Schedule Publish Date</label>
          <DatetimePicker
            v-model="form.publish_date"
            flat
            solo
            required
            class="mt-2"
            placeholder="Schedule Feature Publish Date"
            :error-messages="form.$getError('publish_date')"
            :loading="form.$busy"
            :disabled="form.$busy"
            greater-than-curent
          />
          <div class="mt-4">
            <v-btn
              class="btn-review mr-4 px-6"
              height="40px"
              width="100%"
              @click="handleOpenReview"
            >
              Preview
            </v-btn>
          </div>
          <div class="mt-4">
            <v-btn
              v-if="hasUpdatePermission"
              type="submit"
              color="primary"
              class="mr-4 px-6"
              height="40px"
              width="100%"
              :loading="form.$busy"
              @click="submit"
              >{{ buttonLabel }}</v-btn
            >
          </div>
        </v-col>

        <v-col cols="12" md="6">
          <span class="red--text"><strong>* </strong></span
          ><label class="text-field-label">Banner</label>
          <NewsBannerUpload
            ref="bannerUploader"
            class="my-2"
            :loading="form.$busy"
            :error="form.$getError('banner')"
            :for-update="isUpdate"
            @updated="form.$clearError('banner')"
            :showUploadImageBtn="hasUpdatePermission"
          />
          <p class="hint">Ideal image size 700px x 390px</p>

          <label class="text-field-label">Images</label>
          <NewsFileUpload
            ref="imagesUploader"
            class="my-2"
            :loading="form.$busy"
            :error="form.$getError('images')"
            :for-update="isUpdate"
            @updated="form.$clearError('images')"
            :showUploadImageBtn="hasUpdatePermission"
          />

          <NewsReactions
            v-if="form.reaction_summary"
            :reaction-summary="form.reaction_summary"
          />
          <v-card
            v-if="newsComments.length > 0"
            :loading="loading"
            elevation="0"
          >
            <label class="text-field-label">
              {{ newsComments.length }} Comment(s)</label
            >
            <v-virtual-scroll
              height="400"
              :items="newsComments"
              item-height="120"
            >
              <template v-slot="{ item, index, active }">
                <v-list-item :key="item.id">
                  <v-list-item-avatar>
                    <v-img
                      :src="item.author.avatar_permanent_thumb_url"
                    ></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-card class="virtual_scroll_comments">
                      <div class="row-container">
                        <v-list-item-title class="title_fullname">
                          {{ item.author.full_name }}
                        </v-list-item-title>
                      </div>
                      <v-list-item-subtitle
                        @click="expandItem(index)"
                        class="sub-title-comment"
                        >{{ getReadmoreText(item.body) }}
                        <span v-if="item.body.length > 47"> ... </span>
                        <span
                          v-if="item.body.length > 47"
                          class="text_readmore"
                        >
                          read more</span
                        >
                      </v-list-item-subtitle>
                    </v-card>
                    <v-card-text class="time_comment">{{
                      getDateFormat(item.created_at)
                    }}</v-card-text>
                  </v-list-item-content>
                  <v-btn
                    @click="handleUpdateComment(item, index)"
                    class="ma-0 btn_hide"
                    :loading="loading && loadingItemIndex === index"
                  >
                    {{ item.is_hide === 1 ? 'Unhide' : 'Hide' }}
                  </v-btn>
                </v-list-item>
                <v-dialog
                  v-model="dialog"
                  v-if="dialog && dialogItem === index"
                  width="500"
                >
                  <v-card>
                    <v-card-title> {{ item.author.full_name }}</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <div class="text-caption">
                        {{ item.body }}
                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn @click="closeExpand(index)">Close</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
            </v-virtual-scroll>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <v-dialog
      v-model="showPrompt"
      max-width="520px"
      @click:outside="cancelPrompt"
    >
      <v-form ref="formGenerateText" @submit.prevent="generateText">
        <v-card>
          <v-card-text class="pb-0">
            <v-container class="pb-0">
              <v-row>
                <v-col cols="12">
                  <div class="d-flex justify-space-between align-items-center">
                    <label class="text-field-label">Prompt</label>
                    <v-tooltip left content-class="tooltip-content">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          color="#383d3f"
                          class="mr-1"
                          icon
                          right
                          v-on="on"
                        >
                          <v-icon>mdi-help-circle</v-icon>
                        </v-btn>
                      </template>
                      <span
                        >💡 Tip: Be specific! Instead of “Write a welcome
                        message for Tracey” Try "Write a welcome message for
                        Tracey. Tracey likes crafts and mahjong. Tracey has a
                        dog, Spot." Once generated, we recommend that you review
                        before publishing.</span
                      >
                    </v-tooltip>
                  </div>
                  <p class="mt-2">
                    <span class="font-weight-bold" color="black">Note:</span>
                    Need help writing your article? Just type a few words about
                    your topic, and we will generate a draft for you. You can
                    choose a Short, Medium, or Long format based on your needs.
                    You can easily edit the text before publishing. Give it a
                    try!
                  </p>
                  <v-textarea
                    flat
                    solo
                    required
                    hide-details
                    class="mt-2"
                    placeholder="What do you want to write about?"
                    v-model="formPrompt.prompt"
                    :error-messages="formPrompt.$getError('prompt')"
                    :loading="formPrompt.$busy"
                    :disabled="formPrompt.$busy"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="pb-5 pt-2 px-6 prompt-action">
            <v-tabs>
              <v-tab
                class="pa-3"
                v-for="item in optionTabs"
                :key="item.value"
                :ref="item.value"
                @click="changeTabOptionPrompt(item.value)"
              >
                {{ item.text }}
              </v-tab>
            </v-tabs>
            <v-spacer></v-spacer>
            <v-btn
              color="black"
              class="font-weight-bold"
              text
              @click="cancelPrompt()"
            >
              Close
            </v-btn>
            <v-btn
              color="white"
              :class="{ primary: !!formPrompt.prompt }"
              text
              name="generateText"
              :loading="formPrompt.$busy"
              :disabled="!formPrompt.prompt"
              @click="generateText"
            >
              Send
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <NewsReview
      :show-review="showReview"
      :form="form"
      @close="handleCloseReview"
      :image-drafts="imageFormDraft"
      :banner-draft="bannerDraft"
      :publish-date="publishDate"
    />
  </div>
</template>
<script>
/**
 * ==================================================================================
 * News Form
 * ==================================================================================
 **/
import { mapState, mapActions } from 'vuex'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Form from '@/utils/form'
import NewsFileUpload from '@/views/Home/News/components/NewsFileUpload'
import NewsReactions from '@/views/Home/News/components/NewsReactions'
import NewsBannerUpload from '@/views/Home/News/components/NewsBannerUpload'
import SelectMultipleBuildings from '@/components/fields/SelectMultipleBuildings.vue'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import { dateFormat } from '@/utils/date'
import ContentEditor from '@/components/fields/ContentEditor.vue'
import NewsReview from '@/views/Home/News/components/NewsReview'
import MarkdownIt from 'markdown-it'
import TurndownService from 'turndown'
import DatetimePicker from '@/components/fields/DatetimePicker'
import { mdiStarFourPoints, mdiAlphaI, mdiAlphaA } from '@mdi/js'
import { LIMIT_TEXT } from '@/utils/enums/New'

export default {
  components: {
    NewsFileUpload,
    NewsBannerUpload,
    NewsReactions,
    SelectMultipleBuildings,
    ContentEditor,
    NewsReview,
    DatetimePicker,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  props: {
    news: {
      type: Object,
      default: () => {
        return null
      },
    },
    accessAllBuilding: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: new Form({
        title: '',
        content: '',
        is_active: false,
        is_comment_allowed: false,
        building_id: [],
        banner: null,
        images: [],
        publish_date: '',
      }),
      loading: false,
      newsComments: [],
      loadingItemIndex: -1,
      dialog: false,
      dialogItem: -1,
      showReview: false,
      imageFormDraft: [],
      bannerDraft: null,
      publishDate: null,
      icons: {
        star: mdiStarFourPoints,
        mdiAlphaA: mdiAlphaA,
        mdiAlphaI: mdiAlphaI,
      },
      showPrompt: false,
      formPrompt: new Form({
        prompt: '',
        type: 'short',
      }),
      optionTabs: LIMIT_TEXT,
      generateLoading: false,
    }
  },

  computed: {
    ...mapState({
      createdNews: (state) => state.news.newsDetails,
      permissions: (state) => state.auth.permissions,
    }),

    header() {
      return this.isUpdate ? 'News Article Information' : 'Create News Article'
    },

    buttonLabel() {
      return this.isUpdate ? 'Update' : 'Create'
    },

    submitApi() {
      return this.isUpdate ? this.updateNews : this.createNews
    },

    isUpdate() {
      return !!this.news
    },

    hasUpdatePermission() {
      if (this.isUpdate) {
        return (
          validatePermissions([PERMISSION.NEWS_UPDATE], this.permissions) &&
          this.accessAllBuilding
        )
      } else {
        return validatePermissions([PERMISSION.NEWS_CREATE], this.permissions)
      }
    },

    hasPermissionAi() {
      return validatePermissions(
        [PERMISSION.USE_AI_FOR_ARTICLES],
        this.permissions
      )
    },
  },

  watch: {
    news() {
      this.initForm()
    },
  },

  methods: {
    ...mapActions({
      createNews: 'news/createNews',
      updateNews: 'news/updateNews',
      updateComments: 'comment/updateComments',
      getNewsComments: 'comment/getNewsComments',
      generateTextAi: 'news/generateTextAi',
    }),

    initForm() {
      if (this.news) {
        this.form.title = this.news.title
        this.form.content = this.convertMarkdownToHtml(this.news.content)
        this.form.is_active = this.news.is_active
        this.form.is_comment_allowed = this.news.is_comment_allowed

        const buildingIds = [...this.news.buildings].map((item) => item.id)
        this.form.building_id = buildingIds

        this.form.images = this.news.images
        this.form.banner = this.news.banner
        this.form.reaction_summary = Array.isArray(this.news.reaction_summary)
          ? {}
          : this.news.reaction_summary
        this.form.publish_date = this.news?.publish_date
          ? this.formatDateToDatePicker(this.news.publish_date)
          : ''
        this.publishDate = this.news.publishDate
      }
    },
    async submit() {
      if (this.form.$busy || !this.validate()) return

      this.form.$busy = true
      this.form.$clearErrors()

      /**
       * v-comboxbox value not getting updated when its still on focus
       * and submit was run
       * https://github.com/vuetifyjs/vuetify/issues/3424
       */
      let forUpdate = this.isUpdate
      await this.submitApi(this.getFormData())
        .then(() => {
          if (forUpdate) {
            this.form.$busy = false
            this.showSnackbar('News article details successfully updated!')
          } else {
            this.showSnackbar('News article successfully created!')
            this.$router.push({
              name: 'news',
            })
          }
        })
        .catch((err) => {
          this.form.$busy = false
          this.form.$setErrors(this.getValidationErrors(err))
        })
    },

    /**
     * Validate form values
     * @return {Boolean}
     */
    validate() {
      this.form.$clearErrors()

      if (this.isUpdate) return true

      if (!this.$refs.bannerUploader.hasData()) {
        this.form.$setError('banner', 'Banner is required')
      }

      return !this.form.$hasErrors()
    },

    getFormData() {
      let form = this.form.$data()

      delete form.banner
      delete form.images

      if (this.isUpdate) {
        form.id = this.news.id
        form.content = this.convertHtmlToMarkdown(form.content)
        return form
      } else {
        const formData = new FormData()
        for (var field in form) {
          switch (field) {
            /* ...append normally everything else */
            case 'building_id':
              if (Array.isArray(form[field])) {
                form[field].forEach((id) =>
                  formData.append('building_id[]', id)
                )
              }
              break
            case 'content':
              formData.append(
                'content',
                this.convertHtmlToMarkdown(form[field])
              )
              break
            default:
              formData.append(field, form[field])
              break
          }
        }

        formData.append('banner', this.$refs.bannerUploader.getImage())
        this.$refs.imagesUploader.getImages(formData)

        return formData
      }
    },

    convertMarkdownToHtml(markdown) {
      const md = new MarkdownIt()
      return md.render(markdown || '')
    },

    convertHtmlToMarkdown(html) {
      // Convert HTML to Markdown (use an HTML-to-Markdown library here if necessary)
      const turndownService = new TurndownService({
        headingStyle: 'atx',
      })
      return turndownService.turndown(html) // Simplified example
    },

    formatDateToDatePicker(input) {
      const date = new Date(input)

      // Format the date as `Y-m-dTH:i:s.vZ`
      const formattedDate = date.toISOString().replace(/\.\d+Z$/, (match) => {
        return '.' + match.slice(1, 4) + 'Z' // Trim microseconds to 3 digits
      })

      return formattedDate
    },

    expandItem(index) {
      this.dialog = true
      this.dialogItem = index
    },

    closeExpand(index) {
      this.dialog = false
      this.dialogItem = index
    },

    loadComments() {
      this.loading = true
      this.getNewsComments(this.$route.params.id)
        .then((res) => {
          this.newsComments = res?.data?.data ?? []
        })
        .finally(() => {
          this.loading = false
          this.loadingItemIndex = -1
        })
    },

    handleUpdateComment(item, index) {
      this.loading = true
      this.loadingItemIndex = index
      this.closeExpand(index)
      this.updateComments(item.id, item.is_hide)
        .then()
        .finally(() => {
          this.loadComments()
        })
    },

    getDateFormat(date) {
      return dateFormat(date)
    },

    getReadmoreText(text) {
      return text.length > 45 ? text.slice(0, 45) : text
    },

    handleCloseReview() {
      this.showReview = false
    },

    handleOpenReview() {
      this.showReview = true
      this.imageFormDraft = this.$refs.imagesUploader.imageCraft()
      this.bannerDraft = this.$refs.bannerUploader.imageCraft()
    },

    async generateText() {
      if (this.formPrompt.$busy) return
      let formData = this.formPrompt.$data()
      this.formPrompt.$busy = true
      this.generateLoading = true

      await this.generateTextAi(formData)
        .then((data) => {
          this.form.content = data.content
        })
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.form.$busy = false
          this.generateLoading = false
          this.cancelPrompt()
        })
    },

    changeTabOptionPrompt(value) {
      this.formPrompt.type = value
    },

    cancelPrompt() {
      this.showPrompt = false
      this.formPrompt = new Form({
        prompt: '',
        type: 'short',
      })
    },
  },

  created() {
    this.loadComments()
  },
}
</script>
<style lang="scss">
.news-form {
  &__form {
    max-width: 1000px;
  }

  @media (max-width: 768px) {
    &__form {
      max-width: 100%;
    }
  }

  .hint {
    font-size: 12px;
    /* Adjust the font size as needed */
    color: #888;
    /* Choose a color for the hint text */
  }
}

.v-btn:not(.v-btn--round).v-size--default {
  &.btn_hide {
    height: 25px;
    box-shadow: none !important;
    color: red !important;
    text-transform: uppercase !important;
    background: none !important;
    background-color: transparent !important;
    font-size: 10px;
    /* Adjust the font size as needed */
    font-weight: bold;
    /* Set the font weight to bold */
  }
}

.title_fullname {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-weight: bold;
  /* Set the font weight to bold */
}

.sub-title-comment {
  cursor: pointer;
}

.time_comment {
  font-size: 12px;
  padding: 0px 0px 0px 0px;
  text-align: end;
}

.text_readmore {
  color: red;
}

.header_comment {
  font-size: 12px;
}

.virtual_scroll_comments {
  background: #dad3bf4d !important;
  box-shadow: none !important;
  padding: 10px !important;
}

.v-list-item__avatar {
  top: -10px;
}

.row-container {
  display: flex;
  align-items: center;
  /* Optional: To vertically center items */
}

.btn_hide {
  position: absolute;
  top: 20px;
  right: 10px;
  /* Adjust as needed */
  margin-top: 10px;
  /* Adjust margin top as needed */
}

.btn-review {
  .v-btn__content {
    color: var(--v-primary-base);
  }
}

@media (max-width: 768px) {
  .prompt-action {
    display: block;
  }
}

.v-tab {
  text-transform: none;
}

.tooltip-content {
  max-width: 400px !important;
  white-space: normal;
  word-break: break-word;
}

.btn-generate {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  .icon-ai {
    height: 16px;
    width: 16px;
  }

  &__text-ai {
    text-transform: lowercase;
    font-size: 20px;
    font-weight: 600;
  }
}
</style>
