const LIMIT_TEXT = [
  { text: 'Short', value: 'short' },
  { text: 'Medium', value: 'medium' },
  { text: 'Long', value: 'long' },
]

const FINESSES = [
  { text: 'Summarise', value: 'summarise' },
  { text: 'Worksmith', value: 'worksmith' },
  { text: 'Make Professional', value: 'make_professional' },
  { text: 'Make Casual', value: 'make_casual' },
  { text: 'Make Direct', value: 'make_direct' },
  { text: 'Make Friendly', value: 'make_friendly' },
]

export { LIMIT_TEXT, FINESSES }
